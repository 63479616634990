a:not(.Mui-selected) > .RS_MENU_ICONS,
button:not(.Mui-selected) > .RS_MENU_ICONS {
    filter: grayscale(1);
}

.RS_ACCOUNT:before {
    content: url('icons/RS_Icons_Account_Management.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ANALYSIS:before {
    content: url('icons/RS_Icons_Analysis_Alt.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_EMAIL:before {
    content: url('icons/RS_Icons_Email.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_EXPORT_SERVICE_LOCATION:before {
    content: url('icons/RS_Icons_Export_Service_Location.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_GEOCODE_SERVICE_LOCATION:before {
    content: url('icons/RS_Icons_Geocode_Service_Locations.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_HOME:before {
    content: url('icons/RS_Icons_Home.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_IDENTITY:before {
    content: url('icons/RS_Icons_Identity.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_LOGS:before {
    content: url('icons/RS_Icons_Logs.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_MANAGE:before {
    content: url('icons/RS_Icons_Manage.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_MAP:before {
    content: url('icons/RS_Icons_MapView.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_NOTIFICATION:before {
    content: url('icons/RS_Icons_Notificatoin.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_PRINT:before {
    content: url('icons/RS_Icons_Print.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_REPORT:before {
    content: url('icons/RS_Icons_Report.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ROUTE:before {
    content: url('icons/RS_Icons_Route.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SEARCH:before {
    content: url('icons/RS_Icons_Search.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SEQUENCE:before {
    content: url('icons/RS_Icons_Sequence.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ZOOM:before {
    content: url('icons/RS_Zoom_To_Route.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.FA_SEARCH:before {
    content: url('icons/FA_Search.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.RS_TRAVEL_PATH:before {
    content: url('icons/RS_Icons_Route.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_TRAVEL_PATH_GR:before {
    content: url('icons/RS_Icons_Route.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: grayscale(1);
}

.FA_COLOR_RAMP:before {
    content: url('icons/FA_Color_Ramp.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.FA_COLOR_RAMP_GR:before {
    content: url('icons/FA_Color_Ramp.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.FA_TAG:before {
    content: url('icons/FA_Tag.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.FA_TAG_GR:before {
    content: url('icons/FA_Tag.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.RS_SEQUENCE:before {
    content: url('icons/RS_Icons_Sequence.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_SEQUENCE_GR:before {
    content: url('icons/RS_Icons_Sequence.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: grayscale(1);
}

.FA_USER:before {
    content: url('icons/FA_User.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.FA_USERS:before {
    content: url('icons/FA_Users.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.FA_USER_GEAR:before {
    content: url('icons/FA_User_Gear.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(57%) sepia(33%) saturate(7117%) hue-rotate(347deg) brightness(101%) contrast(102%);
}

.FA_USER_GR:before {
    content: url('icons/FA_User.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.FA_USERS_GR:before {
    content: url('icons/FA_Users.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.FA_USER_GEAR_GR:before {
    content: url('icons/FA_User_Gear.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.DR_DRIVE:before {
    content: url('icons/DR_Driver.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_DAILY:before {
    content: url('icons/DR_Daily.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_OT_THRESHOLD:before {
    content: url('icons/DR_OT_Threshold.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_OT_HOUR:before {
    content: url('icons/DR_OT_Hour.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.TR_TRUCK:before {
    content: url('icons/TR_Truck.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.TR_TRUCK_CAPACITY:before {
    content: url('icons/TR_Truck_Capacity.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.TR_DEMAND:before {
    content: url('icons/TR_Demand.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.TR_SERVICE_SIDE:before {
    content: url('icons/TR_Service_Side.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_ROUTE:before {
    content: url('icons/RT_Route.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_ROUTE_TIME:before {
    content: url('icons/RT_Route_Time.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_START_LOCATION:before {
    content: url('icons/RT_Start_Facility.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_BREAK:before {
    content: url('icons/RT_Breaks.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_DEPART:before {
    content: url('icons/RT_Depart.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_PRE_TRIP:before {
    content: url('icons/RT_Pre_Trip.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_POST_TRIP:before {
    content: url('icons/RT_Post_Trip.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RT_TRIP:before {
    content: url('icons/RT_Trip.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SERVICE_TIME_SCALE:before {
    content: url('icons/RS_ServiceTimeScale.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_PIN:before {
    content: url('icons/RS_Pin.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_UNPIN:before {
    content: url('icons/RS_Unpin.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_FLIP:before {
    content: url('icons/RS_Flip.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ADD_FACILITY:before {
    content: url('icons/RS_Add_Facility.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_DELETE_FACILITY:before {
    content: url('icons/RS_Delete_Facility.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ZOOM_TO_SELECT:before {
    content: url('icons/RS_Zoom_To.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_DISPATCH_VIEW:before {
    content: url('icons/RS_Dispatch_View.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SPLIT:before {
    content: url('icons/RS_Split.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_RESEQUENCE:before {
    content: url('icons/RS_Resequence.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ABSORB:before {
    content: url('icons/RS_Absorb.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_DISPATCH_SCENARIO:before {
    content: url('icons/RS_DispatchScenarios.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ROUTE_SUMMARY:before {
    content: url('icons/RS_Route_Summary.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_MANAGE_FACILITY:before {
    content: url('icons/RS_Manage_Facility.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SELECT_BY_POLYGON:before {
    content: url('icons/RS_Select_By_Polygon.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SELECT_BY_STREET:before {
    content: url('icons/RS_Select_By_Street.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SELECT_BY_STREET_SIDE:before {
    content: url('icons/RS_Select_By_Street_Side.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_REMOVE_FROM_SELECTION:before {
    content: url('icons/RS_Remove_From_Selection.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_ADD:before {
    content: url('icons/RS_Add.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_EDIT:before {
    content: url('icons/RS_Edit.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_COPY:before {
    content: url('icons/RS_Copy.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_DELETE:before {
    content: url('icons/RS_Delete.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SEQUENCE:before {
    content: url('icons/RS_Sequence_Layer.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.RS_SEQUENCE_GR:before {
    content: url('icons/RS_Sequence_Layer.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.RS_TRAVEL_PATH:before {
    content: url('icons/RS_Travel_Path_Layer.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_TRAVEL_PATH_GR:before {
    content: url('icons/RS_Travel_Path_Layer.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(73%) sepia(0%) saturate(142%) hue-rotate(189deg) brightness(86%) contrast(80%);
}

.RS_LABEL:before {
    content: url('icons/RS_Tag.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_LABEL_RED:before {
    content: url('icons/RS_Tag.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(70%) sepia(92%) saturate(630%) hue-rotate(358deg) brightness(100%) contrast(103%);
}

.RS_LABEL_GR:before {
    content: url('icons/RS_Tag.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
    filter: invert(58%) sepia(3%) saturate(7%) hue-rotate(327deg) brightness(88%) contrast(77%);
}

.RS_SELECT_BY_RECTANGLE:before {
    content: url('icons/RS_SelectByRectangle.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_CLEAR_SELECTION:before {
    content: url('icons/RS_ClearSelection.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_MANAGE_PROJECTS:before {
    content: url('icons/RS_ManageProjects.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_MANAGE_SCENARIOS:before {
    content: url('icons/RS_ManageScenarios.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_REFRESH_DATA:before {
    content: url('icons/RS_RefreshData.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_RESHAPE_ZONE:before {
    content: url('icons/RS_ReshapeZone.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_COPY_TO_DISPATCHER:before {
    content: url('icons/RS_Copy_To_Dispatcher.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_EXPORT_SERVICE_PATTERN:before {
    content: url('icons/RS_ExportServicePatternChanges.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_MANAGE_SERVICE_PATTERN:before {
    content: url('icons/RS_ManageServicePatterns.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.RS_TOGGLE_SERVICE_PATTERN:before {
    content: url('icons/RS_ShowServicePatterns.svg');
    display: block;
    height: 1.25em;
    width: 1.25em;
}

.TR_DAILY_USD:before {
    content: url('icons/TR_Daily.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.TR_DAILY_CAD:before {
    content: url('icons/TR_Daily.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.TR_DAILY_EUR:before {
    content: url('icons/TR_Daily_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.TR_DAILY_GBP:before {
    content: url('icons/TR_Daily_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_DISTANCE_USD:before {
    content: url('icons/DR_Distance.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_DISTANCE_CAD:before {
    content: url('icons/DR_Distance.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_DISTANCE_EUR:before {
    content: url('icons/DR_Distance_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_DISTANCE_GBP:before {
    content: url('icons/DR_Distance_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_HOUR_USD:before {
    content: url('icons/DR_Hour.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_HOUR_CAD:before {
    content: url('icons/DR_Hour.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_HOUR_EUR:before {
    content: url('icons/DR_Hour_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_HOUR_GBP:before {
    content: url('icons/DR_Hour_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_OT_PAY_HOUR_USD:before {
    content: url('icons/DR_OT_Pay_Hour.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_OT_PAY_HOUR_CAD:before {
    content: url('icons/DR_OT_Pay_Hour.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_OT_PAY_HOUR_EUR:before {
    content: url('icons/DR_OT_Pay_Hour_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_OT_PAY_HOUR_GBP:before {
    content: url('icons/DR_OT_Pay_Hour_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_STOP_USD:before {
    content: url('icons/DR_Stop.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_STOP_CAD:before {
    content: url('icons/DR_Stop.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_STOP_EUR:before {
    content: url('icons/DR_Stop_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_STOP_GBP:before {
    content: url('icons/DR_Stop_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_DEMAND_USD:before {
    content: url('icons/DR_Demand.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_DEMAND_CAD:before {
    content: url('icons/DR_Demand.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
.DR_DEMAND_EUR:before {
    content: url('icons/DR_Demand_Euro.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}

.DR_DEMAND_GBP:before {
    content: url('icons/DR_Demand_Pound.svg');
    display: block;
    height: 1.5em;
    width: 1.5em;
}
