html,
body {
    height: 100%;
    overflow: hidden;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    body {
        zoom: 0.8;
    }
}

#root {
    height: calc(100% - 36px);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.MuiTable tr td .icon_button,
.MuiTable-root tr td .icon_button,
.formTable tr td .icon_button {
    visibility: hidden !important;
}

.MuiTable tr:hover td .icon_button,
.MuiTable-root tr:hover td .icon_button,
.formTable tr:hover td .icon_button {
    visibility: visible !important;
}

.MuiTable tr:hover td,
.MuiTable-root tr:hover td {
    background-color: #cce7e7 !important;
}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.currencyInput,
.floatInput,
.durationInput {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000de;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
}

button:focus {
    outline: none !important;
}

#root > div {
    z-index: 100;
}

footer {
    position: fixed;
    z-index: 0;
    left: 0;
    bottom: 0;
    height: 32px;
    width: 100%;
    margin: 0 0;
    /*border-top:1px solid #ccc;*/
    text-align: center;
    font-size: 14px;
    /*background-color: #516477;*/
}

footer .credit {
    margin-top: 16px;
    margin-bottom: 0;
    color: #000;
    display: inline-block;
}

.RS.RS_MAP {
    position: absolute;
    left: 10px;
}

.RS.RS_DISPATCH_VIEW {
    position: absolute;
    left: 5px;
}

#RO_MANAGE div.MuiToolbar-regular {
    min-height: 40px !important;
}

#routeListDiv div.dx-tab.dx-tab-selected {
    background-color: #e6e6e6;
}

#routeListDiv div.dx-tab {
    background-color: #fff;
}

#map_ZoomIn {
    position: absolute;
    top: 42px;
    right: 75px;
}

#map_ZoomOut {
    position: absolute;
    top: 42px;
    right: 35px;
}

#zoomByDrawingPolygon {
    position: absolute;
    top: 42px;
    right: 115px;
}

#clearSelectionButton {
    position: absolute;
    top: 42px;
    right: 160px;
}

#selectionToolsButton {
    position: absolute;
    top: 42px;
    right: 200px;
}

.center_input input {
    text-align: center;
}

.esri-print__layout-tab-list,
.esri-print__panel-container.esri-print__advanced-options-section {
    display: none !important;
}

#NC_RouteList > div {
    max-width: 100% !important;
}

.dx-page-indexes {
    visibility: hidden !important;
}

#RouteToolbar .MuiSwitch-switchBase,
#dispatchPane .MuiSwitch-switchBase {
    top: 0;
    left: 3px;
    position: absolute;
}

#RouteToolbar .MuiSwitch-root,
#dispatchPane .MuiSwitch-root {
    color: rgba(60, 60, 60, 0.9);
}

#bottomDiv .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
    display: inline-block !important;
}

#RefreshWorkspaceTable thead.MuiTableHead-root span.material-icons {
    position: absolute;
    right: -25px;
}

#RefreshWorkspaceTable .MuiTable tr:hover td,
#RefreshWorkspaceTable .MuiTable-root tr:hover td {
    background-color: #d5dce4 !important;
}

#RefreshWorkspaceTable th.MuiTableCell-head:nth-child(1),
#RefreshWorkspaceTable th.MuiTableCell-head:nth-child(2) {
    width: calc(49% - 120px) !important;
}

#RefreshWorkspaceTable th.MuiTableCell-head:nth-last-child(2),
#RefreshWorkspaceTable th.MuiTableCell-head:last-child {
    width: 120px !important;
}

#Workspace_Select .select__value-container.select__value-container--has-value {
    display: flex;
    flex-wrap: nowrap;
}

.RS_HIDDEN {
    visibility: hidden;
}

#streetModeDiv {
    height: 210px;
    padding: 10px;
    width: 200px;
    background-color: white;
}

#streetModeDiv.reduced {
    height: 160px;
}

#overviewDiv {
    width: 200px;
    height: 150px;
    padding: 1px;
    background-color: white;
}

#reportDiv {
    width: 300px;
    height: 220px;
    padding: 9px;
    background-color: white;
    position: absolute;
    top: 139px;
    left: 385px;
}

#exportDiv {
    width: 300px;
    height: 180px;
    padding: 9px;
    background-color: white;
    position: absolute;
    display: block;
    top: 140px;
    left: 385px;
}

#routeListDiv {
    width: 325px;
    padding: 9px;
    background-color: white;
    z-index: 1000;
}

#instructionDiv {
    z-index: 99;
    position: absolute;
    left: 40%;
    margin-left: -175px;
    text-align: center;
    height: 30px;
    width: 30%;
    background: rgba(25, 25, 25, 0.4);
    color: white;
}

#measurementToolsDiv {
    position: absolute;
    top: 381px;
    right: 47px;
    cursor: default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 0;
}

#selectionToolsDiv > div {
    position: absolute;
    top: 181px;
    right: 250px;
    cursor: default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 0;
}

#printDiv {
    width: 200px;
    height: 180px;
    padding: 10px;
    background-color: white;
}

#EnterPlannerDialog #closeButton {
    position: absolute;
}

#projectNameInput div.MuiInputBase-root,
#scenarioNameInput div.MuiInputBase-root {
    height: 30px;
}

#PlannerModeAlert .MuiAlert-message {
    padding: 4px 0;
}

#global_loading_spinner {
    position: fixed;
    z-index: 99999999 !important;
    background: rgba(225, 225, 225, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#SelectionDetailDialog .MuiBackdrop-root,
#TripsInfoDialog .MuiBackdrop-root {
    background-color: transparent;
}

#routeListDiv table .dx-state-invisible {
    display: inline-block !important;
}

.RS_GRID_ICON {
    font-size: 10px !important;
}

#tripsInfoDiv .MuiInputBase-root {
    font-size: 10px !important;
}

#tripsInfoDiv .MuiToolbar-root {
    min-height: 20px;
}
#bottomDiv .dx-button-mode-contained.dx-button-success {
    background-color: #ee8a22;
}
.serviceAreaBoundaryWidget {
    box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
}
.serviceAreaBoundaryWidget--state-selected {
    background-color: #16a8a6 !important;
    color: #fff !important;
}

#bottomDiv .dx-datagrid .dx-header-filter {
    font: 10px/1 DXIcons !important;
}

body .walkme-player {
    zoom: 0.75;
    z-index: 1500 !important;
}

#nextSequenceSelect {
    padding: 8px;
}
#routeSummaryGrid .dx-column-indicators,
#serviceLocationGrid .dx-column-indicators {
    float: right !important;
}
#routeSummaryGrid td[role='columnheader'],
#serviceLocationGrid td[role='columnheader'] {
    text-align: center !important;
}

#MapResizeBar {
    width: 100%;
    height: 3px;
    cursor: row-resize;
    padding: 4px 0 0;
    position: absolute;
    margin-top: -5px;
    left: 0;
    z-index: 100;
    background-color: transparent;
    text-align: center;
}

#MapResizeBar svg {
    position: absolute;
    top: -8px;
    color: #999;
}

#MapResizeBar:hover {
    background-color: #1976d2;
}
